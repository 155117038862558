@media print {
    .text-gray-800.text-md.font-bold.ml-6 input[type="checkbox"],
    .text-gray-800.text-md.font-bold.ml-6 label {
      display: none;
    }
  }
  

  @media print {
    .show-details-label {
      display: none;
    }
  }
  